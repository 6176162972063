
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import GButton from '@/components/gsk-components/GskButton.vue';
import DataGrid from '@/components/DataGrid.vue';
import { RpaOpsModule } from '@/store/om27.module';
import {
  DashboardCategory,
  LogicalBot,
  RpaDashboard,
  UserPreferences,
} from '@/types/om27.types';
import MainFilter from '@/components/MainFilter.vue';
import { Promised } from 'vue-promised';
import { getQuery } from '@/utils/routing';

@Component({
  components: {
    Grid,
    GridCell,
    GButton,
    DataGrid,
    MainFilter,
    Promised,
  },
})
export default class Om27Main extends Vue {
  get hasSearch() {
    const query = getQuery(this, 'search', { toArray: false, toNumber: false });
    return !!query;
  }

  get bots(): LogicalBot[] {
    return RpaOpsModule.logicalBots;
  }

  get showActionNeededOnly(): boolean {
    return RpaOpsModule.showActionNeededOnly;
  }

  get categories(): DashboardCategory[] {
    return RpaOpsModule.categories;
  }

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  filter: number[] = [];
  handleFilter(filter: number[]): void {
    this.filter = filter;
  }

  promise: Promise<RpaDashboard> | null = null;
  created(): void {
    this.promise = RpaOpsModule.getDashboard();
  }
}
